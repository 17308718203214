<template>
  <div>
    <div class="card">
      <div class="card-header">
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileChange($event)"
          multiple
          hidden
        />
        <i class="fad fa-info-circle text-primary"></i>
        Glisser-déposez vos images ci-dessous ou
        <a href="#" @click.prevent="selectFiles()">
          cliquez ici pour sélectionner les fichiers à télécharger</a
        >.
      </div>
      <div
        class="gallery-container"
        @drop.prevent="handleFileDrop($event)"
        @dragover.prevent
      >
        <div v-show="photos.length === 0" class="text-center my-3">
          <h1>Déposez vos fichiers ici</h1>
          <i class="fad fa-cloud-upload-alt fa-10x text-secondary"></i>
        </div>
        <draggable
          :list="photos"
          v-bind="dragOptions"
          class="gallery"
          @start="drag = true"
          @end="drag = false"
          @change="savePosition(photos)"
        >
          <figure v-for="file in photos" :key="file.id">
            <img
              :src="file.url"
              loading="lazy"
              @click="toggleMediaSelection(file)"
            />

            <figcaption>{{ file.name }}</figcaption>
            <div class="metadata">
              <i v-show="isMediaSelected(file)" class="fad fa-check-square"></i>
              <span class="filesize"
                >{{ Math.floor(file.size / 1024) }} kb</span
              >
              <a
                href="#"
                @click.prevent="removeFile(file)"
                title="Remove"
                class="text-danger"
              >
                <i class="fad fa-trash"></i>
              </a>
            </div>
          </figure>
        </draggable>
      </div>
    </div>
    <div>
      <!-- Modal-->
      <transition
        @enter="startTransitionModal"
        @after-enter="endTransitionModal"
        @before-leave="endTransitionModal"
        @after-leave="startTransitionModal"
      >
        <div class="modal fade" v-if="showModal" ref="modal">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header bg-primary">
                <h5 class="modal-title" id="exampleModalLabel">
                  Transfert des fichiers en cours
                </h5>
                <button
                  class="close"
                  type="button"
                  @click="closeFileTransferWindow()"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <ul>
                  <li
                    v-for="entry in files"
                    :key="entry.file.name"
                    class="py-1"
                  >
                    {{ entry.file.name }} ({{
                      Math.floor(entry.file.size / 1024)
                    }}
                    kb)

                    <small
                      v-if="entry.error"
                      :class="
                        entry.error == 500 ? 'badge-danger' : 'badge-warning'
                      "
                      class="badge"
                      >{{ getErrorMessage(entry.error) }}</small
                    >
                    <span
                      v-else
                      :class="entry.uploaded ? 'badge-success' : 'badge-light'"
                      class="badge"
                      >{{ entry.uploaded ? "Chargé" : "En cours" }}</span
                    >
                  </li>
                </ul>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  @click="closeFileTransferWindow()"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="modal-backdrop fade d-none" ref="backdrop"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gallery-container {
    width: 100%;
    padding: 1em 2%;
  .gallery {
      margin-bottom: 5%;
    --THUMBNAILS_WIDTH: 350px;
    --THUMBNAILS_HEIGHT: 350px;
    @media (max-width: 400px) {
        --THUMBNAILS_WIDTH: 300px;
        --THUMBNAILS_HEIGHT: 300px;
    }
    display: grid;
    grid-template-columns:  repeat( auto-fit, var(--THUMBNAILS_WIDTH));
    grid-template-rows: auto;
    column-gap: clamp(0.1em, 3%, 1vw);
    row-gap: clamp(0.1em, 3%, 3vh);
    justify-items: center;
    align-items: center;
    figure {
        height: var(--THUMBNAILS_HEIGHT);
        width: var(--THUMBNAILS_WIDTH);
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:hover{
                cursor: move;
            }
        }
        figcaption {
            position: absolute;
            bottom: 0;
            font-size: 12px;
            color: #fff;
            padding: 2% 3%;
            background: rgba(0, 0, 0, 0.6);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            min-width: 100%;
        }
        .metadata {
            position: absolute;
            right: 0;
            bottom: -1.5rem;
            font-size: 0.9rem;
        }
    }
  }
}
.modal{
    small.badge{
        font-size: 80%!important;
    }
}
</style>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    stock: Object,
    mediaUrl: String,
    uploadMediaUrl: String,
    badgeId: String,
  },
  data() {
    return {
      showModal: false,
      photos: [],
      files: [],
      selectedFiles: [],
      dragOptions: [
        {
          forceFallback: true,
          animation: 200,
          ghostClass: "ghost",
        },
      ],
      statusCodeMessages: new Map([
        [409, "Fichier déjà chargé."],
        [500, "Impossible de charger ce fichier."],
        [403, "Chargement non autorisé."],
      ]),
    };
  },
  created() {
    fetch(this.mediaUrl)
      .then((res) => res.json())
      .then((res) => {
        this.photos.push(...res);
      })
      .catch((e) => {
        console.error(JSON.stringify(e.message));
      });
  },
  computed: {
    mediaCount() {
      return this.photos.length;
    },
  },
  methods: {
    savePosition(value) {
      this.$http
        .put(this.mediaUrl + "/order", JSON.stringify(value.map((x) => x.id)))
        .then(() => {
          this.photos = value;
        })
        .catch((e) => {
          console.error(JSON.stringify(e.message));
        });
    },
    selectFiles() {
      this.$refs.file.click();
    },
    toggleMediaSelection(file) {
      return; // feature is disabled until batch actions are available
      // commentend code to avoid console warning
      //   if (this.isMediaSelected(file)) {
      //     this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
      //   } else {
      //     this.selectedFiles.push(file);
      //   }
    },
    isMediaSelected(file) {
      return this.selectedFiles.indexOf(file) > -1;
    },
    handleFileDrop(event) {
      let droppedFiles = event.dataTransfer.files;
      if (!droppedFiles || droppedFiles.length === 0) {
        return;
      }

      this.addFiles(droppedFiles);
    },
    handleFileChange(event) {
      if (!event.target.files || event.target.files.length === 0) {
        return;
      }

      this.addFiles(event.target.files);
    },
    closeFileTransferWindow() {
      this.files = this.files.filter((x) => !x.uploaded);
      this.showModal = false;
    },
    addFiles(files) {
      this.showModal = true;

      [...files].forEach((f) => {
        let file = {
          file: f,
          uploaded: false,
          error: null,
        };
        this.files.push(file);

        let formData = new FormData();
        formData.append("file", f);

        this.$http
          .post(this.uploadMediaUrl, formData)
          .then((res) => {
            file.uploaded = true;
            this.photos.push(res.data);
            this.savePosition(this.photos);
            this.updateMediaCountBadge();
          })
          .catch((e) => {
            let statusCode = parseInt(e.message.split("code ").pop());
            file.error = isNaN(statusCode) ? 500 : statusCode;
            console.error(JSON.stringify(e.message));
          });
      });
    },
    removeFile(file) {
      this.$http
        .delete(this.mediaUrl + "/" + file.id)
        .then(() => {
          const index = this.photos.indexOf(file);
          if (index > -1) {
            this.photos.splice(index, 1);

            this.updateMediaCountBadge();
          }
        })
        .catch((e) => {
          console.error(JSON.stringify(e.message));
        });
    },
    updateMediaCountBadge() {
      window.$("#" + this.badgeId + " span.badge").text(this.mediaCount);
    },
    startTransitionModal() {
      this.$refs.backdrop.classList.toggle("d-block");
      if (this.$refs.modal) {
        this.$refs.modal.classList.toggle("d-block");
      }
    },
    endTransitionModal() {
      this.$refs.backdrop.classList.toggle("show");
      this.$refs.modal.classList.toggle("show");
    },
    getErrorMessage(statusCode) {
      return this.statusCodeMessages.has(statusCode)
        ? this.statusCodeMessages.get(statusCode)
        : this.statusCodeMessages.get(500);
    },
  },
};
</script>
