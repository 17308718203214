<template>
  <div class="stock checkbox-label-equipment w-100">
    <equipment-selector
      :value="value"
      :selected="isSelected || isGroupSelected"
      :disabled="isGroupSelected"
      :group="group"
      @change="updateEquipment($event)"
    />
  </div>
</template>

<script>
import EquipmentSelector from "./EquipmentSelector.vue";

export default {
  props: {
    group: {
      type: String,
      default: "",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isGroupSelected: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { EquipmentSelector },
  methods: {
    updateEquipment(equipment) {
      if (equipment.isSelected) {
        this.$store.dispatch("stockEquipment/addEquipment", equipment.id);
      } else {
        this.$store.dispatch("stockEquipment/removeEquipment", equipment.id);
      }
    },
  },
};
</script>
