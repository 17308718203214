<template>
  <div class="d-flex justify-content-around w-100">
    <div class="input-group-prepend">
      <button
        type="button"
        :class="['btn btn-checkbox btn-light mr-2', selected ? 'check' : '']"
        :disabled="disabled"
        aria-haspopup="true"
        aria-expanded="false"
        @click="toggle()"
      ></button>
    </div>

    <div class="equipment-name-container w-100" @click="toggle()">
      <div class="equipment-name-title">
        {{ value.name }}
        <i
          v-if="value.description"
          v-tooltip="value.description"
          class="fa fa-info-circle text-primary ml-1"
        ></i>
      </div>
    </div>

    <div class="input-group-append">
      <span class="input-group-text">{{formatPriceToCurrency( value.retail_price) }} $</span>
    </div>
  </div>
</template>

<style inline>
.equipment-name-container:hover {
  cursor: pointer;
}
</style>

<script>
export default {
  props: {
    group: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    toggle() {
      let equipment = Object.assign({}, this.value);
      equipment.isSelected = !this.selected;

      this.$emit("change", equipment);
    },
    formatPriceToCurrency(price) {
       return  price.toLocaleString('fr-CA')
    },
  },
};
</script>
