const SET_EQUIPMENT = "SET_EQUIPMENT";
const SET_GROUPS = "SET_GROUPS";
const ADD_EQUIPMENT = "ADD_EQUIPMENT";
const REMOVE_EQUIPMENT = "REMOVE_EQUIPMENT";
const ADD_GROUP = "ADD_GROUP";
const REMOVE_GROUP = "REMOVE_GROUP";

export default {
    state: {
        equipment: [],
        groups: []
    },
    getters: {
        getEquipmentIds: state => {
            return state.equipment;
        },
        getGroupIds: state => {
            return state.groups;
        },
        equipmentCount: state => {
            return state.equipment.length;
        },
        equipmentGroupCount: state => {
            return state.groups.length;
        }
    },
    mutations: {
        [SET_EQUIPMENT](state, list) {
            state.equipment = list;
        },
        [SET_GROUPS](state, list) {
            state.groups = list;
        },
        [ADD_EQUIPMENT](state, equipmentId) {
            state.equipment.push(equipmentId);
        },
        [REMOVE_EQUIPMENT](state, equipmentId) {
            const index = state.equipment.indexOf(equipmentId);
            if (index > -1) {
                state.equipment.splice(index, 1);
            }
        },
        [ADD_GROUP](state, groupId) {
            state.groups.push(groupId);
        },
        [REMOVE_GROUP](state, groupId) {
            const index = state.groups.indexOf(groupId);
            if (index > -1) {
                state.groups.splice(index, 1);
            }
        }
    },
    actions: {
        setSelectedEquipment({ commit }, list) {
            commit(SET_EQUIPMENT, list);
        },
        setSelectedGroups({ commit }, list) {
            commit(SET_GROUPS, list);
        },
        addEquipment({ commit, state }, equipmentId) {
            commit(ADD_EQUIPMENT, equipmentId);
        },
        removeEquipment({ commit, state }, equipmentId) {
            commit(REMOVE_EQUIPMENT, equipmentId);
        },
        addGroup({ commit, state }, groupId) {
            commit(ADD_GROUP, groupId);
        },
        removeGroup({ commit, state }, groupId) {
            commit(REMOVE_GROUP, groupId);
        }
    }
};
