<template>
  <div class="card-columns" id="equipments-assigned">
    <div v-show="nonEmptyGroups.length === 0">
      <p>
        Aucun équipement présentement disponible.
        <a href="/equipment/create">Cliquez ici pour en ajouter un</a>.
      </p>
    </div>

    <div
      v-for="group in nonEmptyGroups"
      v-show="nonEmptyGroups.length !== 0"
      :key="group.id"
      class="card"
    >
      <div class="card-header bg-info text-light d-flex">
        <div
          :class="
            isGroupSelectable(group) ? 'custom-control custom-checkbox' : ''
          "
        >
          <input
            v-if="isGroupSelectable(group)"
            :id="'group' + group.id"
            :checked="isGroupSelected(group)"
            type="checkbox"
            class="custom-control-input custom-control-input-teal"
            @click="updateGroup(group)"
          />
          <component
            :is="isGroupSelectable(group) ? 'label' : 'span'"
            :class="isGroupSelectable(group) ? 'custom-control-label' : ''"
            :for="'group' + group.id"
            >{{ group.name }}</component
          >
        </div>
        <span v-if="isGroupSelectable(group)" class="ml-auto">{{
          getGroupTotalValue(group)
        }}</span>
      </div>
      <ul class="equipment-list list-group list-group-flush">
        <li
          v-for="equipment in group.equipment"
          class="list-group-item d-flex justify-content-between w-100"
          :key="equipment.id"
        >
          <equipment-item
            :value="equipment"
            :group="group.name"
            :is-group-selected="isGroupSelected(group)"
            :is-selected="isSelected(equipment)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EquipmentItem from "./EquipmentItem.vue";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { EquipmentItem },
  computed: {
    selectedEquipment() {
      return this.$store.state.stockEquipment.equipment;
    },
    selectedGroups() {
      return this.$store.state.stockEquipment.groups;
    },
    nonEmptyGroups() {
      return this.items.filter((x) => x.equipment.length > 0);
    },
  },
  methods: {
    isSelected(equipment) {
      return this.selectedEquipment.includes(equipment.id);
    },
    getGroupTotalValue(group) {
      return `${group.retail_price.toLocaleString("fr-CA")} $`;
    },
    isGroupSelected(group) {
      return this.selectedGroups.includes(group.id);
    },
    updateGroup(group) {
      if (this.isGroupSelected(group)) {
        this.$store.dispatch("stockEquipment/removeGroup", group.id);
      } else {
        group.equipment.forEach((x) =>
          this.$store.dispatch("stockEquipment/removeEquipment", x.id)
        );
        this.$store.dispatch("stockEquipment/addGroup", group.id);
      }
    },
    isGroupSelectable(group) {
      return group.id > 0;
    },
  },
};
</script>
