<template>
  <div class="card">
    <div class="card-body">
      <equipment-list :items="availableEquipment" />
    </div>
    <div class="card-footer">
      <button
        class="btn btn-success"
        @click="persistEquipmentList()"
        :disabled="saving"
      >
        <i v-if="saving" class="fa fa-spinner fa-spin"></i>
        Enregistrer
      </button>
    </div>
  </div>
</template>

<script>
import EquipmentList from "../Components/EquipmentList";

export default {
  components: { EquipmentList },
  props: {
    stock: Object,
    availableEquipment: Array,
    equipmentWithoutGroup: Array,
    updateRoute: String,
  },
  data() {
    return {
      saving: false,
    };
  },
  created() {
    const selectedEquipment = this.stock.equipment.map((x) => x.id);
    const selectedGroups = this.stock.equipment_groups.map((x) => x.id);

    this.$store.dispatch(
      "stockEquipment/setSelectedEquipment",
      selectedEquipment
    );
    this.$store.dispatch("stockEquipment/setSelectedGroups", selectedGroups);
  },
  computed: {
    equipmentCount() {
      return this.$store.getters["stockEquipment/equipmentCount"];
    },
    equipmentGroupCount() {
      return this.$store.getters["stockEquipment/equipmentGroupCount"];
    },
  },
  methods: {
    persistEquipmentList() {
      this.saving = true;
      const equipmentIds = this.$store.getters[
        "stockEquipment/getEquipmentIds"
      ];
      const groupIds = this.$store.getters["stockEquipment/getGroupIds"];

      this.$inertia.post(
        this.updateRoute,
        {
          equipment: equipmentIds,
          groups: groupIds,
        },
        {
          onSuccess: () => {
            this.$toasted.show(
              "Vos changements ont été correctement enregistrés.",
              {
                position: "bottom-center",
                duration: 1500,
                iconPack: "fontawesome",
                icon: "fa-check-circle",
                type: "success",
              }
            );

            window
              .$("#equipment span.badge")
              .text(this.equipmentCount + this.equipmentGroupCount);
          },
          onError: (errors) => {
            this.$toasted.show(
              "Une erreur s'est produite. Veuillez réessayer.",
              {
                icon: "fa-exclamation-circle",
                type: "error",
              }
            );
            console.error(errors);
          },
          onFinish: () => (this.saving = false),
        }
      );
    },
  },
};
</script>
