/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

jQuery(function() {
    $('[data-toggle="tooltip"]').tooltip();
});

import { App, plugin } from "@inertiajs/inertia-vue";
import Vue from "vue";
import modules from "./store/modules";
import Vuex from "vuex";
import Toasted from "vue-toasted";
import VTooltip from "v-tooltip";
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(plugin);
Vue.use(Toasted, {
    position: "bottom-center",
    duration: 1500,
    iconPack: "fontawesome"
});
Vue.use(VTooltip);
Vue.use(Vuex);
let store = new Vuex.Store({
    modules
});
Vue.use(VueAxios, axios)

const el = document.getElementById("app");

if (el) {
    new Vue({
        render: h =>
            h(App, {
                props: {
                    initialPage: JSON.parse(el.dataset.page),
                    resolveComponent: name => require(`./Pages/${name}`).default
                }
            }),
        store
    }).$mount(el);
}
